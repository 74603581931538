import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';
import buttonStyles from 'apputil/button-styles';

export default makeStyles({
  ...buttonStyles,
  paper: theme.mixins.gutters({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  fourTopBotMargin: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  headerIcon: {
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
  },

  headerBlue: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginRight: 'auto !important',
    marginLeft: 'auto !important',
    width: '50%',
    borderBottomWidth: theme.spacing(0.2),
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      width: '60%',
    },
    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  body: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

});
