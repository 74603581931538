import React, { useState } from 'react';
import { bool } from 'prop-types';
import loadable from '@loadable/component';

import useStyles from './styles';

const ContactUsForm = loadable(() => import('views/contact-us/conatct-us-form'));
const ContactUsSuccess = loadable(() => import('views/contact-us/success'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const Paper = loadable(() => import('@material-ui/core/Paper'));

const ContactUsView = ({ loading }) => {
  const styles = useStyles();
  const [emailResult, setEmailResult] = useState({});
  const [viewToSee, setViewToSee] = useState('form');

  return (
    <section className={styles.fourTopBotMargin}>
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10} lg={8}>
      <Paper className={styles.paper} elevation={4}>
        {viewToSee === 'form' && <ContactUsForm onAfterSubmit={(result) => {
          if (result) {
            setEmailResult(result);
            setViewToSee('success');
          }
        }}
        pageLoading={loading}/>}
        {viewToSee === 'success' && <ContactUsSuccess emailResult={emailResult}/>}
      </Paper>
      </Grid>
    </Grid>
    </section>
  );
};

ContactUsView.propTypes = {
  loading: bool.isRequired,
};

export default ContactUsView;
